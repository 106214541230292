import React from "react";

class JuegoResponsable extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="p-1 mx-auto">
        <p className="text-small text-justify">
          <h5 className="text-center mb-4 mt-4">
            ¿Que es el juego responsable?
          </h5>
          <br />
          El juego responsable refiere a una elección racional que hace el
          jugador, considerando sus circunstancias personales a los fines de
          desarrollar una actividad de juego segura, controlada y divertida,
          impidiendo que se convierta en un problema.
          <br className="text-center mb-4" />
          <h5 className="text-center mb-4 mt-4">
            ¿Que es el juego problemático?
          </h5>
          <br />
          Se da cuando la persona:
          <ol>
            <li>Pierde el control de su conducta de juego.</li>
            <li>
              No puede medir la cantidad de dinero que apuesta ni en el tiempo
              que dedica al juego.
            </li>
            <li>
              Continúa con esta actividad a pesar de las consecuencias y
              complicaciones que le ocasiona{" "}
            </li>
          </ol>
          <br />
          <h5 className="text-center mb-4 mt-4">
            ¿Cómo reconocemos que una persona tiene problemas con el juego?
          </h5>
          Principalmente se caracteriza porque no puede parar de jugar,
          causándole problemas económicos, en el trabajo, con su familia y
          amigos/as, como también problemas de autoestima y ansiedad. No se
          trata de no jugar, sino jugar con responsabilidad.
          <br />
          <h5 className="text-center mb-4 mt-4">
            ¿Creés que alguien que conocés necesita ayuda?{" "}
          </h5>
          Para asistencia, orientación o información podés llamarnos al 0800 222
          1700 las 24 hs del día. Accedé a la autoevaluación para conocer si
          estas teniendo un problema con el juego:
          <br cl />
          <a
            href="https://www.loteriaderionegro.gob.ar/?contID=59698"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue" }}
          >
            {" "}
            https://www.loteriaderionegro.gob.ar/?contID=59698{" "}
          </a>
        </p>
      </div>
    );
  }
}

export default JuegoResponsable;
